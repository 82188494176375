<template>
  <div>
    <b-card no-body>
      <div class="pt-1 pl-3">
        <b-form-group>
          <b-form-radio-group
            v-model="currentStatus"
            :options="statusOptions"
            switches
          />
        </b-form-group>
      </div>
    </b-card>
    <div
      v-if="loaded"
      class="text-center"
    >
      <b-spinner variant="primary" />
    </div>
    <div v-if="!loaded && items.length > 0">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="mb-2"
      >
        <b-card
          :class="{'d-flex flex-row':true}"
          no-body
        >
          <div class="d-flex flex-grow-1 min-width-zero">
            <div class="card-body p-1 d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
              <p class="mb-0 font-weight-bold text-primary w-15 w-sm-100">
                {{ item.ticket }}
              </p>
              <p class="mb-0 text-small w-15 w-sm-100">
                <b-icon
                  v-if="item.sentCurrency.ismobile"
                  class="text-danger"
                  icon="phone"
                  font-scale="1"
                />
                {{ item.senderAddress !== "null" ? item.senderAddress : 'Portefeuille' }}
              </p>
              <p class="mb-0 text-small w-20 w-sm-100">
                <b-icon
                  class="text-danger"
                  icon="arrow-up"
                  font-scale="1"
                />
                {{ item.sentCurrency.sigle + ' ( '+ item.realSentValue + ' )' }}
              </p>
              <p class="mb-0 text-small w-15 w-sm-100">
                <b-icon
                  class="text-success"
                  icon="arrow-down"
                  font-scale="1"
                />
                {{ item.receivedCurrency.sigle + ' ( '+ item.receivedValue + ' )' }}
              </p>
              <!-- <p class="mb-0 text-small w-15 w-sm-100">
                  {{ item.email }}
                </p> -->
              <p class="mb-0 text-small w-20 w-sm-100">
                <b-icon
                  v-if="item.receivedCurrency.ismobile"
                  class="text-success"
                  icon="phone"
                  font-scale="1"
                />
                <b-icon
                  v-else-if="item.receivedCurrency.dev_code === 'VISA'"
                  class="text-success"
                  icon="credit-card"
                  font-scale="1"
                />
                <b-icon
                  v-else
                  class="text-success"
                  icon="arrow-down"
                  font-scale="1"
                />
                {{ item.receiverAddress }}
              </p>
              <p class="mb-0 text-small w-10 w-sm-100">
                {{ item.updated_at | moment("DD/MM/YY HH:mm") }}
              </p>
              <!-- <p class="mb-0 text-small w-30 w-sm-100">
                {{ item.address + ' / ' + item.city + ' / ' + item.country }}
              </p> -->
            </div>
            <div class="custom-control custom-checkbox pl-1 align-self-center pr-1">
              <div class="mb-1 text-small w-5 w-sm-100">
                <b-dropdown
                  variant="link"
                  dropright
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <b-icon
                      class="p-1 text-primary"
                      icon="three-dots-vertical"
                      font-scale="1.2"
                    />
                  </template>
                  <b-dropdown-item @click="openModal(item)">
                    <span class="ml-1">Détails</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div v-if="!loaded && items.length === 0">
      <b-card
        :class="{'d-flex flex-row':true}"
        no-body
      >
        <div class="pl-2 d-flex flex-grow-1 min-width-zero">
          <div class="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
            <div class="text-center">
              <p class="mb-0 font-weight-bold text-primary w-100 w-sm-100">
                <span v-if="currentStatus === 'CREATED'">Aucun paiment initié..</span>
                <span v-if="currentStatus === 'PENDING'">Aucun paiment en traitement.</span>
                <span v-if="currentStatus === 'WAITING'">Aucun paiment en attente de validation.</span>
                <span v-if="currentStatus === 'VALID'">Aucun paiment validé.</span>
                <span v-if="currentStatus === 'FAILED'">Aucun paiment echoué.</span>
              </p>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div
      v-if="!loaded && items.length > 0"
      class="mt-2 mb-2"
    >
      <div class="float-left">
        <b-form-select
          v-model="perPage"
          :options="pageOptions"
        />
      </div>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
        align="right"
      />
    </div>

    <b-modal
      v-if="showFirstManualModal"
      v-model="showFirstManualModal"
      centered
      hide-footer
      title="Transaction Manuelle"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Ticket">
              <b-form-input
                v-model="currentTransaction.ticket"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Montant envoyé">
              <b-form-input
                v-model="currentTransaction.realSentValue"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Montant reçu">
              <b-form-input
                v-model="currentTransaction.receivedValue"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Status">
              <b-form-select
                v-model="currentState"
                :options="stateOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Motif">
              <b-form-textarea
                v-model="motif"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <div>
          <div
            v-if="firstProcess"
            class="text-center mt-2 mb-1"
          >
            <div>
              <b-spinner variant="primary" />
              <p>Confirmation en cours ...</p>
            </div>
          </div>
          <b-row>
            <b-col>
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="tryToCloseFisrtExchange"
              >Confirmer la réception</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="currentTransaction.sentDevCode === 'BTC'">
          <b-row>
            <b-col>
              <a
                :href="currentTransaction.crypto_status_url"
                target="_blank"
                class="btn btn-outline-primary w-100"
              >Voir la transaction</a>
            </b-col>
            <b-col v-if="currentTransaction.sentState === 'PENDING'">
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="redirectOnClose(currentTransaction.ticket)"
              >Clôturer</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="showSecondManualModal"
      v-model="showSecondManualModal"
      centered
      hide-footer
      title="Clôture Manuelle"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Ticket">
              <b-form-input
                v-model="currentTransaction.ticket"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Montant à recevoir">
              <b-form-input
                v-model="currentTransaction.receivedValue"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Message">
              <b-form-textarea
                v-model="message"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <div>
          <div
            v-if="process"
            class="text-center mt-2 mb-1"
          >
            <div>
              <b-spinner variant="primary" />
              <p>Clôture en cours ...</p>
            </div>
          </div>
          <b-row>
            <b-col>
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="tryToCloseExchange"
              >Valider</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="showDetailsModal"
      v-model="showDetailsModal"
      centered
      hide-footer
      title="Détails"
    >
      <div>
        <b-tabs
          justified
          content-class="mt-3"
        >
          <b-tab
            title="Echange"
            active
          >
            <p class="text-primary">
              TICKET :  {{ currentTransaction.ticket }}
            </p>
            <b-row>
              <b-col sm="12">
                <b-table-simple
                  caption-top
                  responsive
                  hover
                >
                  <caption>Envoyé</caption>
                  <b-tr class="w-100">
                    <b-td>
                      Je donne
                    </b-td>
                    <b-td>
                      {{ currentTransaction.sentDevCode }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Montant à echanger
                    </b-td>
                    <b-td>
                      {{ currentTransaction.realSentValue }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Adresse d'envoi
                    </b-td>
                    <b-td>
                      {{ currentTransaction.senderAddress }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                    <b-td>
                      Expéditeur
                    </b-td>
                    <b-td>
                      {{ currentTransaction.senderAddressName }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                    <b-td>
                      Adresse de paiment
                    </b-td>
                    <b-td>
                      {{ currentTransaction.sentRecipientAddress }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                    <b-td>
                      Capture d'écran
                    </b-td>
                    <b-td>
                      <a
                        :href="urrentTransaction.proof[0].name"
                        target="_blank"
                        rel="noopener noreferrer"
                      >Voir</a>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Status
                    </b-td>
                    <b-td>
                      {{ currentTransaction.sentState }}
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
              <b-col sm="12">
                <b-table-simple
                  caption-top
                  responsive
                  hover
                >
                  <caption>Reçu</caption>
                  <b-tr class="w-100">
                    <b-td>
                      Je recois
                    </b-td>
                    <b-td>
                      {{ currentTransaction.receivedDevCode }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Montant à recevoir
                    </b-td>
                    <b-td>
                      {{ currentTransaction.receivedValue }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.receivedDevCode !== 'VISA'">
                    <b-td>
                      Adresse de reception
                    </b-td>
                    <b-td>
                      {{ currentTransaction.receiverAddress }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.receivedDevCode === 'WAVE_CI' || currentTransaction.receivedDevCode === 'WAVE_SENEGAL' || currentTransaction.receivedDevCode === 'VISA'">
                    <b-td>
                      Destinataire
                    </b-td>
                    <b-td>
                      {{ currentTransaction.receiverAddressName }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.receivedDevCode === 'VISA'">
                    <b-td>
                      ID Carte
                    </b-td>
                    <b-td>
                      {{ currentTransaction.visaCardId }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.receivedDevCode === 'VISA'">
                    <b-td>
                      N° Carte
                    </b-td>
                    <b-td>
                      {{ currentTransaction.visaLastDigit }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Status
                    </b-td>
                    <b-td>
                      {{ currentTransaction.receivedState }}
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Client"
            title-item-class="text-uppercase"
          >
            <b-row>
              <b-col sm="12">
                <b-table-simple
                  responsive
                  hover
                >
                  <b-tr>
                    <th>
                      Nom
                    </th>
                    <b-td>
                      {{ currentTransaction.owner.name + ' ' + currentTransaction.owner.surname }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Sexe
                    </b-td>
                    <b-td>
                      {{ currentTransaction.owner.sex }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Localisation
                    </b-td>
                    <b-td>
                      {{ currentTransaction.owner.country.toUpperCase() + ' / ' + currentTransaction.owner.city + ' / ' + currentTransaction.owner.address }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Email
                    </b-td>
                    <b-td>
                      {{ currentTransaction.owner.email }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Téléphone
                    </b-td>
                    <b-td>
                      {{ currentTransaction.owner.phone }}
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <hr>
        <div>
          <b-row>
            <b-col v-if="getAutomateState(currentTransaction.sentDevCode)">
              <b-button
                v-if="currentTransaction.sentState === 'WAITING'"
                class="w-100"
                variant="outline-primary"
                @click="openFirstModal"
              >Traitement manuel</b-button>
            </b-col>
            <b-col v-if="currentTransaction.sentState === 'SUCCESSFUL' && (currentTransaction.receivedDevCode === 'WAVE_CI' || currentTransaction.receivedDevCode === 'WAVE_SENEGAL' || currentTransaction.receivedDevCode === 'BTC' || currentTransaction.receivedDevCode === 'VISA')">
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="openSecondModal"
              >Clôture manuelle</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="currentTransaction.sentDevCode === 'BTC'">
          <b-row>
            <b-col>
              <a
                :href="currentTransaction.crypto_status_url"
                target="_blank"
                class="btn btn-outline-primary w-100"
              >Voir la transaction</a>
            </b-col>
            <b-col v-if="currentTransaction.sentState === 'PENDING'">
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="redirectOnClose(currentTransaction.ticket)"
              >Clôturer</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      items: [],
      manualCurrencies: [],
      fields: [
        { key: 'index', label: 'Ticket' },
        { key: 'sentMemo', label: 'Objet' },
        { key: 'date', label: 'Date et heure' },
        { key: 'action', label: 'Détails', class: 'text-center' },
      ],
      statusOptions: [
        { value: 'CREATED', text: 'CREEE' },
        { value: 'PENDING', text: 'EN ATTENTE' },
        { value: 'WAITING', text: 'EN TRAITEMENT' },
        { value: 'FAILED', text: 'ECHEC' },
        { value: 'SUCCESSFUL', text: 'VALIDER' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      loaded: false,
      totalItems: 0,
      currentStatus: 'WAITING',
      currentTransaction: null,
      showDetailsModal: false,
      showFirstManualModal: false,
      showSecondManualModal: false,
      motif: null,
      currentState: null,
      message: null,
      stateOptions: [
        { value: null, text: 'Choisir' },
        { value: 'SUCCESSFUL', text: 'Reçu' },
        { value: 'FAILED', text: 'Non reçu' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      process: 'closeSecondManualProcess',
      success: 'closeSecondManualSuccess',
      error: 'closeSecondManualError',

      firstProcess: 'closeFirstManualProcess',
      firstSuccess: 'closeFirstManualSuccess',
      firstError: 'closeFirstManualError',
    }),
    getReceivedAmount() {
      let value = 0
      value = this.currentTransaction.realSentValue - (this.currentTransaction.sentValue - this.currentTransaction.realSentValue)
      return value
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllTransactions()
      },
    },
    currentPage(val) {
      if (val) {
        this.getAllTransactions()
      }
    },
    currentStatus(val) {
      if (val) {
        this.getAllTransactions()
      }
    },
    perPage(val) {
      if (val) {
        this.getAllTransactions()
      }
    },
    success(val) {
      if (val) {
        this.closeFirstModal()
        this.closeSecondModal()
        this.closeModal()
        this.getAllTransactions()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Clôture manuelle',
            icon: 'CheckCircleIcon',
            text: 'Transaction clôturée avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Clôture manuelle',
            icon: 'XCircleIcon',
            text: 'Transaction non effectuée.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    firstSuccess(val) {
      if (val) {
        this.closeFirstModal()
        this.closeSecondModal()
        this.closeModal()
        this.getAllTransactions()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Confirmation de reception',
            icon: 'CheckCircleIcon',
            text: 'Confirmer avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    firstError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Confirmation de reception',
            icon: 'XCircleIcon',
            text: 'Transaction non effectuée.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
  },
  mounted() {
    this.getAllTransactions()
    this.getAllCurrencies()
  },
  methods: {
    ...mapActions(['closeSecondManualTransaction', 'closeFirstManualTransaction']),
    tryToCloseExchange() {
      this.closeSecondManualTransaction({
        id: this.currentTransaction.id,
        ticket: this.currentTransaction.ticket,
        message: this.message,
        value: this.currentTransaction.receivedValue,
        transactionId: null,
      })
    },
    tryToCloseFisrtExchange() {
      this.closeFirstManualTransaction({
        id: this.currentTransaction.id,
        ticket: this.currentTransaction.ticket,
        motif: this.motif,
        sentValue: this.currentTransaction.realSentValue,
        receivedValue: this.currentTransaction.receivedValue,
        state: this.currentState,
      })
    },
    openSecondModal() {
      this.showSecondManualModal = true
    },
    closeSecondModal() {
      this.showSecondManualModal = false
    },
    openFirstModal() {
      this.showFirstManualModal = true
    },
    closeFirstModal() {
      this.showFirstManualModal = false
    },
    redirectOnClose(item) {
      this.$router.push(`/admin/settings/close-transaction/${item}`)
    },
    async getAllCurrencies() {
      this.manualCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data.data.filter(item => item.is_auto === false))
        .catch(() => [])
    },
    getAutomateState(code) {
      const data = this.manualCurrencies
      const manualBuyCurrencies = data.filter(item => item.buy_auto === false)
      const res = manualBuyCurrencies.findIndex(item => item.dev_code === code)
      if (res < 0) {
        return false
      }
      return true
    },
    openModal(item) {
      this.currentTransaction = item
      this.showDetailsModal = true
    },
    closeModal() {
      this.currentTransaction = null
      this.showDetailsModal = false
    },
    async getAllTransactions() {
      this.loaded = true
      this.items = []
      this.items = await
      this.$http
        .get(`/find-all-by-sent-state/${this.currentStatus}/${this.perPage}?page=${this.currentPage}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          return data.data
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>
